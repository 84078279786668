<template>
  <div>
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center">
        <button
          type="button"
          class="btn btn-transparent modal-header__cancel"
          data-dismiss="modal"
          @click="cancel"
        >
          <i class="fe-arrow-left"></i>
        </button>

        <div class="modal-title h5">{{ title || '' }}</div>
      </div>

      <div class="modal-body">
        <Viewer
          class="markdown-viewer"
          :initialValue="content"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { Viewer } from '@toast-ui/vue-editor';

  export default {
    name: 'ShowContentModal',
    components: {
      Viewer
    },
    props: {
      content: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: false
      }
    },
    methods: {
      cancel: function () {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal-body {
    max-height: 500px;
    overflow: auto;
  }
</style>
